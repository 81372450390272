<template>
    <div v-if="loading == false">
        <ChirpList
            :data_source="rows"
            :totalCount="totalCount"
            :column_meta="columnMeta"
            @rowSelected="dynamic_target"
            :loadingColumns="loadingTable"
            :storeKey="storeKey"
            :useOverride="useOverride"
            @cancelOverride="handleCancelOverride"
            :command-columns="commandColumns"
            @buttonInCellClicked="delete_alert"
        >
            <template v-if="$store.state.user.role_id == 1 && criteria.filter.alert_type != 'supervisor_signature' " v-slot:control>
                <div class="flex center">
                    <button class="secondary right-10" @click="toggle_mine_all">
                        <div v-if="all_alerts">Show My Alerts</div>
                        <div v-else>Show Company Alerts</div>
                    </button>
                    <div v-if="all_alerts"><i>(Viewing Company Alerts)</i></div>
                    <div v-else><i>(Viewing My Alerts)</i></div>
                </div>
            </template>
        </ChirpList>
        <AlertDelete v-if="$store.state.user.role_id == 1 || $store.state.user.role_id == 12"
            :open="delete_modal"
            @cancel="cancelDelete"
            @handleDelete="handleDelete"
        />
    </div>
    <div v-else>Loading...</div>
</template>

<script>
    import ChirpList from '@/components/general/list/ChirpList.vue';
    import { tryGetFilter } from '@/util/tryGetFilter';
    import AlertDelete from '@/components/dash/AlertDelete';

    const api_root = 'alerts';

    export default {
        name: 'Alerts',
        components: { AlertDelete, ChirpList },
        props: {
            overrideFilter: {
                type: Object,
                default: null
            },
            show_all_alerts: {
                type: [String, Number],
                default: 0
            }
        },
        data() {
            return {
                rows: [],
                targetPage: 'AlertDetail',
                loading: true,
                loadingTable: false,
                useOverride: false,
                totalCount: 0,
                columnMeta: [
                    { field: 'Due_Date', headerText: 'Due Date', hide: false },
                    { field: 'alert', headerText: 'Alert', hide: false },
                    { field: 'user', headerText: 'User', hide: false },
                    { field: 'client', headerText: 'Client', hide: false },
                    { field: 'page_id', hide: true },
                    { field: 'form_data_id', hide: true },
                    { field: 'client_id', hide: true },
                    { field: 'component_name', hide: true },
                    { field: 'appt_id', hide: true },
                    { field: 'dayt_appt_start', hide: true },
                    { field: 'alert_type', hide: true },
                    { field: 'first_name', hide: true },
                    { field: 'last_name', hide: true },
                    { field: 'user_id', hide: true },
                    { field: 'user_first_name', hide: true },
                    { field: 'user_last_name', hide: true },
                ],
                all_alerts: 0,
                commandColumns: [],
                alert_id: 0,
                alert_type: '',
                delete_modal: 0
            };
        },
        computed: {
            criteria() {
                return this.useOverride && this.overrideFilter ? this.overrideCriteria : this.savedCriteria;
            },
            savedCriteria() {
                return tryGetFilter(this.$store, this.storeKey);
            },
            overrideCriteria() {
                return this.$store.getters['filters/overrideCriteria'](this.storeKey);
            },
            storeKey() {
                return this.pageMeta?.page || 'alerts';
            }
        },
        methods: {
            async load_data() {
                const body = {
                    criteria: this.criteria
                };
                const res = await this.$api.post(`/${api_root}`, body);
                this.rows = res.data.rows;
                this.totalCount = res.data.total_count;

                if (this.rows.length <= 0) {
                    this.columnMeta = [
                        { field: 'Due_Date' },
                        { field: 'alert' },
                        { field: 'user' },
                        { field: 'client' }];
                } else {
                    this.columnMeta = [
                        { field: 'Due_Date', headerText: 'Due Date', hide: false },
                        { field: 'alert', headerText: 'Alert', hide: false },
                        { field: 'user', headerText: 'User', hide: false },
                        { field: 'client', headerText: 'Client', hide: false },
                        { field: 'page_id', hide: true },
                        { field: 'form_data_id', hide: true },
                        { field: 'client_id', hide: true },
                        { field: 'component_name', hide: true },
                        { field: 'appt_id', hide: true },
                        { field: 'dayt_appt_start', hide: true },
                        { field: 'alert_type', hide: true },
                        { field: 'first_name', hide: true },
                        { field: 'last_name', hide: true },
                        { field: 'user_id', hide: true },
                        { field: 'user_first_name', hide: true },
                        { field: 'user_last_name', hide: true },
                    ];
                }

                this.$nextTick(() => {
                    this.loading = false;
                });
            },
            async init() {
                if (this.overrideFilter) {
                    this.useOverride = true;
                    this.$store.commit('filters/applyOverride', {
                        stateKey: this.storeKey,
                        criteria: this.overrideFilter
                    });
                }
                // this.criteria.search.user_ids = this.$store.state.user.id // leave this off, and it works like magic - afv

                this.$store.commit('filters/updateFilter', { // magic sauce to keep override criteria here (from dashboard), so can refresh page =) - afv
                    stateKey: this.storeKey,
                    criteria: this.criteria
                });

                if (this.show_all_alerts) {
                    this.all_alerts = this.show_all_alerts;
                    this.criteria.search.user_ids = 'all';
                }

                const usr = this.$store.state.user;

                if ((usr.role_id === 1 && this.criteria.filter.alert_type == 'ready_to_send') || (usr.role_id === 12 && this.criteria.filter.alert_type == 'ready_to_send')) {
                    const cols = [
                        {
                            field: 'Commands',
                            headerText: 'Delete',
                            commands: [
                                {
                                    buttonOption: {
                                        content: `<span class="material-icons-outlined" style="pointer-events: none">delete</span>`,
                                        cssClass: 'delete-icon pad-5',

                                    }
                                }
                            ]
                        }
                    ];
                    this.$set(this, 'commandColumns', cols);
                }

                await this.load_data();
            },
            handleCancelOverride() {
                this.useOverride = false;
            },
            async modifyCriteria(criteria) {
                if (this.targetPage) {
                    this.$store.commit('filters/update', {
                        criteriaPage: this.targetPage,
                        payload: criteria
                    });
                }
                this.criteriaProps = criteria;
                this.load_data();
            },
            async dynamic_target(row) {
                this.$store.state.current_client_id = row.client_id;
                if (row.alert_type == 'outstanding_balance') {
                    this.$router.push({ name: 'ClientBilling', params: { client_id: row.client_id } });
                } else if (row.alert_type == 'progress_note' || row.alert == 'Progress Note Supervisor Signature') {
                    if (row.form_data_id) {
                        this.$router.push({
                            name: 'ProgressNote',
                            params: { id: row.form_data_id },
                            query: { client_id: row.client_id, appt_id: row.appt_id }
                        });
                    } else {
                        this.$router.push({
                            name: 'Calendar',
                            query: { apptId: row.appt_id, startTime: row.dayt_appt_start }
                        });
                    }
                } else if (row.alert_type == 'ready_to_send') {
                    this.$router.push({ name: 'ClientDocuments', params: { client_id: row.client_id } });
                } else {
                    this.$router.push({ name: row.component_name, params: { record_id: row.form_data_id } });
                }
            },
            async toggle_mine_all(e) {
                this.all_alerts = 1-this.all_alerts
                let new_search = ''
                if (this.all_alerts && this.$store.state.user.role_id == 1) {
                    new_search = 'all';
                } else {
                    new_search = '';
                }
                if (this.criteria.search.user_ids != new_search) {
                    this.criteria.search.user_ids = new_search;
                    await this.load_data();
                }
            },
            async delete_alert(e) {
                if ((this.$store.state.user.role_id == 1 && e.rowData.alert_type == 'ready_to_send') || (this.$store.state.user.role_id == 12 && e.rowData.alert_type == 'ready_to_send')) {
                    this.alert_id = e.rowData.id;
                    this.alert_type = e.rowData.alert_type;
                    this.delete_modal = 1;
                }
            },
            cancelDelete() {
                this.alert_id = 0;
                this.alert_type = '';
                this.delete_modal = 0;
            },
            async handleDelete() {
                try {
                    const res = await this.$api.post(`/${api_root}/deactivate/${this.alert_id}`,{alert_type:this.alert_type});
                    if (res.status >= 200 && res.status < 300) {
                        this.$toasted.success('Successfully deleted alert.');
                        this.cancelDelete()
                        await this.load_data();
                    } else {
                        this.$toasted.error('You are not authorized to delete this alert.');
                    }
                } catch (error) {
                    this.$cl(error);
                    this.$toasted.error('Error deleting alert.');
                }
            },
        },
        async created() {
            await this.init();
        },
        watch: {
            criteria: {
                deep: true,
                async handler() {
                    await this.load_data();
                }
            }
        }
    };
</script>
